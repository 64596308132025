

import * as THREE from 'three';

// import Stats from 'three/examples/jsm/libs/stats.module.js';


let camera, scene, renderer, stats, parameters;
let mouseX = 0, mouseY = 0;


let HEIGHT = window.innerHeight;
let WIDTH = window.innerWidth;
let windowHalfX = WIDTH / 2;
let windowHalfY = HEIGHT / 2;
let fieldOfView = 75;
let aspectRatio = WIDTH / HEIGHT;
let nearPlane = 1;
let farPlane = 3000;
let particleCount = 20000;
let fogHex = 0x000000; 
let fogDensity = 0.0007;
let cameraZ = farPlane / 3;
const materials = [];

init();
animate();

function init() {

    camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
    camera.position.z = cameraZ;

    scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(fogHex, fogDensity);

    const geometry = new THREE.BufferGeometry();
    const vertices = [];


    for (let i = 0; i < particleCount; i++) {

        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 - 1000;
        const z = Math.random() * 2000 - 1000;

        vertices.push(x, y, z);

    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));


    parameters = [
        // [
        //     [1, 1, 0.5], 5
        // ],
        // [
        //     [0.95, 1, 0.5], 4
        // ],
        // [
        //     [0.90, 1, 0.5], 3
        // ],
        [
            [0.85, 1, 0.5], 1.5
        ],
        [
            [0.80, 1, 0.5], 1
        ]
    ];
    let parameterCount = parameters.length;

    for (let i = 0; i < parameterCount; i++) {

        const color = parameters[i][0];
        const size = parameters[i][1];

        // materials[ i ] = new THREE.PointsMaterial( { size: size, map: sprite, blending: THREE.AdditiveBlending, depthTest: false, transparent: true } );
        materials[i] = new THREE.PointsMaterial({ size: size,depthTest: false,transparent: true });

        // materials[ i ].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ], THREE.SRGBColorSpace );

        const particles = new THREE.Points(geometry, materials[i]);

        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

        scene.add(particles);

    }

    //

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(WIDTH, HEIGHT);

    document.body.appendChild(renderer.domElement);
    renderer.domElement.style.zIndex=-1;
    renderer.domElement.style.position="fixed";
    renderer.domElement.style.top=0;
    renderer.domElement.style.left=0;
    renderer.domElement.style.width="100%";
    renderer.domElement.style.height="100%";


    //

    // stats = new Stats();
    // document.body.appendChild(stats.domElement);
    
    // document.body.style.touchAction = 'none';
    window.addEventListener('resize', onWindowResize);
    document.body.addEventListener('pointermove', onPointerMove);
}

function onWindowResize() {
    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}

function onPointerMove(event) {
    if (event.isPrimary === false) return;
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;

}

function animate() {
    requestAnimationFrame(animate);
    render();
    // stats.update();
}

function render() {

    const time = 0.00005;

    camera.position.x += (mouseX - camera.position.x) * 0.05;
    camera.position.y += (- mouseY - camera.position.y) * 0.05;

    camera.lookAt(scene.position);

    for (let i = 0; i < scene.children.length; i++) {

        const object = scene.children[i];

        if (object instanceof THREE.Points) {

            object.rotation.y = time * (i < 4 ? i + 1 : - (i + 1));

        }

    }

    for (let i = 0; i < materials.length; i++) {

        const color = parameters[i][0];

        const h = (360 * (color[0] + time) % 360) / 360;
        materials[i].color.setHSL(h, color[1], color[2]);

    }

    renderer.render(scene, camera);

}